// App.js
import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import validate from 'validate.js';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import { NEONPV_CONFIG } from "config.js";

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const App = () => {
  const [open, setOpen] = React.useState(false);

  const [message, setMessage] = React.useState(null);
  const [header, setHeader] = React.useState(null);

  useEffect(() => {
    try{
      const eventSource = new EventSource(`${NEONPV_CONFIG.HOSTNAME}/sse/connect`);
    
      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          const storageInfo = JSON.parse(localStorage.getItem('userinfo'))
          const memberId = storageInfo.admin_id;
          
          switch(data.event){
            case "connect":
              console.log("SSE connected")
              break;
            case "disconnect":
              console.log("SSE disconnected")
              break;
            case "fire":
              console.log("불꽃감지");
              if((memberId === "admin" || memberId === data.memberId)){
                setHeader("불꽃감지");
                setMessage(`회원사 : ${data.memberId}\n충전소 : ${data.statId}\n충전소 명 : ${data.statName}\n충전기 : ${data.chargerId}\n담당자 전화번호 : ${data.telno}\n주소 : ${data.address}`);
                setOpen(true);
              }
              break;
          }

          
          
        } catch (error) {
          console.error('SSE message parsing error:', error);
        }
      };
  
      eventSource.onerror = (error) => {
        console.error('SSE connection error:', error);
        // 선택적: 연결 재시도 로직
        setTimeout(() => {
          eventSource.close();
          // 새로운 연결 시도
        }, 1000*60);
      };
  
      return () => {
        // 연결해제
        // eventSource.close();
      };
    }catch(err){
      console.log(err);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router history={browserHistory}>
        <Routes />
        {/* Dialog 컴포넌트는 어디에 있든 항상 최상위에 렌더링됩니다 */}
        <Dialog 
          open={open} 
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
        >
          <DialogTitle id="alert-dialog-title">
            {header}
          </DialogTitle>
          <DialogContent style={{ whiteSpace: 'pre-line' }}>
            {message}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </Router>
    </ThemeProvider>
  );
};

export default App;