import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Divider,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import { NEONPV_CONFIG } from "config.js";
import { USER_AUTH, MAX_LENGTH, charger_speed_list, charger_type_list, charger_status_list,
  getChargerTypeCode, getChargerStatusCode, getChargerTypeLabel, getChargerStatusLabel  } from "common/constants";

import { loadChargerModelList } from "utils/LoadConfiguration";
import evchargerApp from "firebaseHellocharger";

const ChargerUpdateForm = (props) => {
  const { className, history, chargerInfo, ...rest } = props;
  const classes = useStyles();

  const [selectedChargerSpeed, setSelectedChargerSpeed] = useState(chargerInfo.powerType);
  const [selectedChargerType, setSelectedChargerType] = useState(getChargerTypeLabel(chargerInfo.chargerType));
  const [selectedChargerStatus, setSelectedChargerStatus] = useState(getChargerStatusLabel(chargerInfo.chargerStatus));
  // 충전기 모델
  const [selectedChargerModel, setSelectedChargerModel] = useState(chargerInfo.chargerModel);

  //충전기모델 리스트
  const [chargerModelList, setChargerModelList] = useState([]);

  /** 충전기 시리얼번호 중복체크 유무 */
  const [verifiedSerialNumber, setVerifiedSerialNumber] = useState(false);

  const userAuth = useSelector((state) => state.user.user_auth);
  const [command, setCommand] = useState('command:');

  const handleSubmit = (event, values) => {
    event.preventDefault();

    if (verifiedSerialNumber == false){
      alert('충전기 시리얼번호 중복체크를 해 주세요.');
      return;
    }

    fetch(`${NEONPV_CONFIG.HOSTNAME}/station/updateChargerInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        chargerSeqNo: chargerInfo.chargerSeqNo,
        statId: chargerInfo.statId,
        chargerId: chargerInfo.chargerId,

        // chargerSpeed: selectedChargerSpeed,
        chargerType: getChargerTypeCode(selectedChargerType),
        // chargerStatus: getChargerStatusCode(selectedChargerStatus),
        powerType: selectedChargerSpeed,
        // chargerIP: values.chargerIP,
        // chargerPort: values.chargerPort,
        chargerMac: values.chargerMacAddr,
        // chargerModel: getChargerModelCode(selectedChargerModel),
        chargerModel: selectedChargerModel,
        // chargerVersion: values.chargerVersion,
        serialNumber: values.serialNumber,
        chargerFirmwareVersion: values.chargerFirmwareVersion,
        chargerLTERouterNo: values.chargerLTERouterNo
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {

      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n${responseData.resultCode}-${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트에 실패했습니다.");
    });
  };

  const firebaseBridge = ((event) => {
    const database = evchargerApp.database();
    const dataRef = database.ref(`auth/${chargerInfo.chargerMac}/command`);
    
    // firebase에 데이터가 적용되면 화면에 출력
    const onDataChange = (snapshot) => {
      setCommand(`command: ${snapshot.val()}`);
    };

    // 이벤트 리스너
    dataRef.on('value', onDataChange);
  });

  // 앱 재부팅
  const handleAppReboot = ((event) => {
    const database = evchargerApp.database();
    const dataRef = database.ref(`auth/${chargerInfo.chargerMac}/command`);

    if(confirm("정말 앱 재부팅을 하시겠습니까?")){
      dataRef.set("soft_reboot").then(() => {

      }).catch((error) => {
        console.error('firebase set data fail - ', error);
      })
    }
  });

  /** 충전기 삭제하기 */
  const handleRemoveChargerInfo = (event) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/station/removeChargerInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        chargerSeqNo: chargerInfo.chargerSeqNo,
        statId: chargerInfo.statId,
        chargerId: chargerInfo.chargerId,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("충전기 삭제에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`충전기 삭제에 실패했습니다.\n${responseData.resultCode}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("충전기 삭제에 실패했습니다.");
    });
  }


    /**
   * 충전기 시리얼번호 - 중복체크
   */
  const doCheckExistedChargerSerialNumber = (event, chargerSerialNumber) => {
    event.preventDefault();

    if (chargerSerialNumber === chargerInfo.serialNumber){
      alert('수정 전 "시리얼번호"입니다.');
      setVerifiedSerialNumber(true);
      return;
    }

    var url = `${NEONPV_CONFIG.HOSTNAME}/station/isExistSerialNumber?serialNumber=`+chargerSerialNumber;

    fetch(url)
    .then(res => res.json())
    .then(snapshot => {

      console.log(snapshot);
      console.log(snapshot.resultData);

      if (snapshot.resultCode == '023'){
        alert('입력 가능한 "시리얼번호"입니다.');
        setVerifiedSerialNumber(true);
      }

      if (snapshot.resultCode == '000'){
        alert('이미 등록된 "시리얼번호"입니다.');
        setVerifiedSerialNumber(false);
      }

    })
    .catch( (err) => {
      console.error(err);
      alert("서버와의 통신에 실패했습니다.");
    });

  }


  const handleBack = () => {
    history.goBack();
  };


  useEffect(() => {

    async function loadChargerModel() {
      let models = await loadChargerModelList();

      setChargerModelList(models);
    }

    loadChargerModel();
    firebaseBridge();
  }, []);


  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          statId: chargerInfo.statId || "", //충전소를 선택하는 dialog box
          chargerId: chargerInfo.chargerId || "", // 충전기번호
          // chargerIP: chargerInfo.chargerIP || "", //충전기 IP
          // chargerPort: chargerInfo.chargerPort || "", //충전기 PORT
          chargerMacAddr: chargerInfo.chargerMac || "", //충전기 MAC주소
          chargerModel: chargerInfo.chargerModel || "", //충전기 모델
          // chargerVersion: chargerInfo.chargerVersion || "", //충전기 버전
          serialNumber: chargerInfo.serialNumber || "", //충전기 시리얼넘버
          chargePointStatus: chargerInfo.chargePointStatus || "", //충전기 상태
          lastHeartbeatTime: chargerInfo.lastHeartbeatTime || "", //최근 heartbeat 수신 시각
          chargerFirmwareVersion: chargerInfo.chargerFirmwareVersion || "", //충전기 펌웨어버전
          chargerLTERouterNo: chargerInfo.chargerLTERouterNo || "", //LTE 라우터 번호
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >

            <Card>
              <CardContent>
                <Grid container spacing={2} >
                  <Grid item md={2} xs={12} >
                    <TextField
                      error={Boolean(touched.statId && errors.statId)}
                      fullWidth
                      helperText={touched.statId && errors.statId}
                      label="충전소ID"
                      name="statId"
                      onBlur={handleBlur}
                      value={chargerInfo.statId}
                      variant="outlined"
                      disabled
                      inputProps={{
                        maxLength: MAX_LENGTH.STAT_ID,
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="충전소 명칭"
                      name="statName"
                      value={chargerInfo.statName}
                      variant="outlined"
                      disabled
                      inputProps={{ maxLength: MAX_LENGTH.STAT_NAME }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="충전소 주소"
                      name="statAddr"
                      value={chargerInfo.statAddr}
                      variant="outlined"
                      disabled
                      inputProps={{ maxLength: MAX_LENGTH.STAT_ADDR }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                </Grid>

                <Divider className={classes.divider} variant="inset" />

                <Grid container spacing={2} >

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerId && errors.chargerId)}
                      fullWidth
                      helperText={touched.chargerId && errors.chargerId}
                      label="충전기번호(최대2자)"
                      name="chargerId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      disabled
                      value={values.chargerId}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_ID }}
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.serialNumber && errors.serialNumber)}
                      fullWidth
                      helperText={touched.serialNumber && errors.serialNumber}
                      label="충전기시리얼번호(최대25자)"
                      name="serialNumber"
                      onBlur={handleBlur}
                      // onChange={handleChange}
                      onChange={ (e) => { handleChange(e); setVerifiedSerialNumber(false); }}
                      required
                      value={values.serialNumber}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_SERIALNUMBER }}
                    />
                  </Grid>

                  <Grid container item md={1} xs={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      variant={verifiedSerialNumber?"contained": "outlined"}
                      color="primary"
                      size="large"
                      fullWidth
                      onClick={(e) => doCheckExistedChargerSerialNumber(e, values.serialNumber)}
                    >
                      중복체크
                    </Button>
                  </Grid>


                  <Grid item md={2} xs={4}>

                    <Autocomplete
                      id="charger_speed_select"
                      options={charger_speed_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedChargerSpeed}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="충전속도 선택"
                          variant="outlined"
                          required
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedChargerSpeed(newValue);
                        }
                      }}
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>

                    <Autocomplete
                      id="charger_type_select"
                      options={charger_type_list}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedChargerType}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="충전기 타입 선택"
                          variant="outlined"
                          required
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedChargerType(newValue);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}></Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargePointStatus && errors.chargePointStatus)}
                      fullWidth
                      helperText={touched.chargePointStatus && errors.chargePointStatus}
                      label="충전기 상태"
                      name="chargePointStatus"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // required
                      disabled
                      value={values.chargePointStatus}
                      variant="outlined"
                      // inputProps={{ maxLength: MAX_LENGTH.CHARGER_ID }}
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.lastHeartbeatTime && errors.lastHeartbeatTime)}
                      fullWidth
                      helperText={touched.lastHeartbeatTime && errors.lastHeartbeatTime}
                      label="최근 수신 시각"
                      name="lastHeatbeatTime"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      // required
                      disabled
                      value={values.lastHeartbeatTime}
                      variant="outlined"
                    />
                  </Grid>


                  <Grid item md={2} xs={4}>
                    <TextField
                      fullWidth
                      label="운영회원사"
                      name="businessName"
                      value={chargerInfo.memberName}
                      variant="outlined"
                      inputProps={{ maxLength: 50 }}
                      disabled
                    />
                  </Grid>


                  {/* <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.chargerIP && errors.chargerIP)}
                      fullWidth
                      helperText={touched.chargerIP && errors.chargerIP}
                      label="IP주소(최대15자)"
                      name="chargerIP"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerIP}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_IP }} //127.127.127.127
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerPort && errors.chargerPort)}
                      fullWidth
                      helperText={touched.chargerPort && errors.chargerPort}
                      label="PORT(최대65535)"
                      name="chargerPort"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerPort}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_PORT }}
                    />
                  </Grid>
 */}


                  {/* <Grid item md={4} xs={8}></Grid> */}

                  <Grid item md={2} xs={4}>
                    <Autocomplete
                      id="charger_model_list"
                      options={chargerModelList}
                      classes={{ option: classes.options, }}
                      autoHighlight
                      getOptionSelected={(option, value) => option === value}
                      value={selectedChargerModel}
                      renderOption={(option) => (
                        <React.Fragment>
                          {option}
                        </React.Fragment>
                      )}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="충전기 모델"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />
                      )}

                      onChange={(event, newValue) => {
                        if (newValue) {

                          setSelectedChargerModel(newValue);
                        }
                      }}
                    />
                  </Grid>

                  {/* <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.chargerVersion && errors.chargerVersion)}
                      fullWidth
                      helperText={touched.chargerVersion && errors.chargerVersion}
                      label="충전기버전"
                      name="chargerVersion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerVersion}
                      variant="outlined"
                      disabled
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_VERSION }}
                    />
                  </Grid> */}


                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerFirmwareVersion && errors.chargerFirmwareVersion)}
                      fullWidth
                      helperText={touched.chargerFirmwareVersion && errors.chargerFirmwareVersion}
                      label="펌웨어버전"
                      name="chargerFirmwareVersion"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerFirmwareVersion}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_FIRMWARE_VERSION }}
                    />
                  </Grid>


                  <Grid item md={2} xs={4}></Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerMacAddr && errors.chargerMacAddr)}
                      fullWidth
                      helperText={touched.chargerMacAddr && errors.chargerMacAddr}
                      label="MAC주소(최대17자)"
                      name="chargerMacAddr"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerMacAddr}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_MAC_ADDR }} //2C-F0-5D-DC-A5-BC
                    />
                  </Grid>

                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.chargerLTERouterNo && errors.chargerLTERouterNo)}
                      fullWidth
                      helperText={touched.chargerLTERouterNo && errors.chargerLTERouterNo}
                      label="LTE라우터번호(최대20자)"
                      name="chargerLTERouterNo"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerLTERouterNo}
                      variant="outlined"
                      inputProps={{ maxLength: MAX_LENGTH.CHARGER_LTE_ROUTER_NO }}
                    />
                  </Grid>

                </Grid>



                <Grid container item className={classes.action}>
                  <Grid
                      container
                      direction="row" alignItems="center" justifyContent="flex-start"
                      item md={4} xs={4}>
                      {<Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={handleAppReboot}
                        className={classes.action}
                      >
                        앱 재부팅
                      </Button>
                      } 
                      &nbsp;{command}
                  </Grid>
                  
                  <Grid container item md={3} xs={4}
                    direction="row" alignItems="center" justifyContent="center"
                  >
                    {userAuth === USER_AUTH.SUB_MEMBER ? '' : (<Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                      disabled={isSubmitting}
                      className={classes.action}
                    >
                      충전기 수정
                    </Button>)}
                    

                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleBack}
                      className={classes.action}
                    >
                      취소
                    </Button>
                  </Grid>

                  {userAuth === USER_AUTH.SUB_MEMBER ? '' : (<Grid
                    container
                    direction="row" alignItems="center" justifyContent="flex-end"
                    item md={5} xs={4}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleRemoveChargerInfo}
                      className={classes.action}
                    >
                      충전기 삭제
                    </Button>
                  </Grid>)}
                  
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

ChargerUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};


export default ChargerUpdateForm;


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },

  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight: 20,
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },

  divider: {
    margin: theme.spacing(2, 0)
  },
}));
