import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { NEONPV_CONFIG } from "config.js";
const fs = require('fs');
const path = require('path');

const { kakao } = window;

const Map = ({ admin_id }) => {
  const history = useHistory();

  useEffect(() => {
    const container = document.getElementById('map');
    const options = {
      // center: new kakao.maps.LatLng(36.466963602575866, 127.74413191924035),
      center: new kakao.maps.LatLng(37.44923512445463, 127.01425095906262),
      level: 9
    };
    
    const map = new kakao.maps.Map(container, options);

    /** 지도 기본 화면 */
    // 일반 지도와 스카이뷰로 지도 타입을 전환할 수 있는 지도타입 컨트롤을 생성합니다
    var mapTypeControl = new kakao.maps.MapTypeControl();

    // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
    // kakao.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
    map.addControl(mapTypeControl, kakao.maps.ControlPosition.TOPRIGHT);

    // 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
    var zoomControl = new kakao.maps.ZoomControl();
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);

    /** 마커데이터 조회 */
    let url = `${NEONPV_CONFIG.HOSTNAME}/station/queryStationList?`;
    if(admin_id !== 'admin'){
      url += `memberId=${admin_id}`
    }
    
    fetch(url).then(res => {
      if(!res.ok){ throw new Error(`Network response was not ok ${res.statusText}`); }
      return res.json();
    }).then(data => {
      const currentTime = new Date(); // 현재시간 생성

      // Kakao Marker Image 객체 생성
      const markerImageGreen = new kakao.maps.MarkerImage('/images/marker_green.png', new kakao.maps.Size(24, 35)); // 이미지 사이즈 조정
      const markerImageRed = new kakao.maps.MarkerImage('/images/marker_red.png', new kakao.maps.Size(24, 35)); // 이미지 사이즈 조정

      // 충전소 조회
      data.resultData.forEach(async element => {
        // console.log(element);
        /** 마커 */
        const position =  new kakao.maps.LatLng(element.latitude, element.longitude);
        const marker = new kakao.maps.Marker(
          {
            position,
            image: markerImageGreen
          });
        marker.setMap(map);
        let isGreen = true;

        /** 마커 - 인포윈도우 */
        let iwContent = ``; // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
        await fetch('/infoWindowContent.html').then(response => {
          if (!response.ok) { throw new Error('Network response was not ok ' + response.statusText); }
          return response.text();
        }).then(async html => {
          // infowindowContent 내용 삽입
          let tempHtml = html;
          tempHtml = tempHtml.replace('{statName}', element.statName);
          tempHtml = element.addr ? tempHtml.replace('{statAddress}', element.addr+element.addr2) : tempHtml.replace('{statAddress}', "");
          tempHtml = element.statId ? tempHtml.replace('{statId}', element.statId) : tempHtml.replace('{statId}', "");
          tempHtml = element.member_name ? tempHtml.replace('{member_name}', element.member_name) : tempHtml.replace('{member_name}', "");
          tempHtml = element.ownerName ? tempHtml.replace('{ownerName}', element.ownerName) : tempHtml.replace('{ownerName}', "");
          tempHtml = element.businessTel ? tempHtml.replace('{businessTel}', element.businessTel) : tempHtml.replace('{businessTel}', "");

          /**
           * 충전기 정보 조회
           */
          let chargerURL = `${NEONPV_CONFIG.HOSTNAME}/station/queryChargerList?`;
          if(admin_id !== 'admin') chargerURL += `memberId=${admin_id}&`;
          chargerURL += `statId=${element.statId}&`;

          await fetch(chargerURL).then(chargerRes => {
            if(!chargerRes.ok){ throw new Error(`Network response was not ok ${chargerRes.statusText}`); }
            return chargerRes.json();
          }).then(chargerData => {
            const resultData = chargerData.resultData;
            let chargerInfoList = "";

            // 결과 값이 배열이 아닌경우
            if (!Array.isArray(resultData)) {
              iwContent = tempHtml;
            }else{
              if(resultData.length > 0){
                resultData.forEach(charger => {
                  // marker
                  const lastHeartbeatTime = new Date(charger.lastHeartbeatTime);
                  const lastHeartbeatTimePlusnine = new Date(lastHeartbeatTime.getTime() + (9 * 60 * 60 * 1000)); // 9시간을 더한 새로운 Date 객체 생성

                  // 현재시간, lastHeartbeat 시간 차이(분)
                  const timeDifference = (currentTime - lastHeartbeatTimePlusnine) / 1000 / 60;
                  if(isGreen && timeDifference > 5){ isGreen = false; }
                  // console.log(timeDifference, element.statId, isGreen);

                  // infowindow contents
                  const chargePointStatus = charger.chargePointStatus;
                  if(timeDifference > 5){
                    chargerInfoList += `<div class="chargerBlock"><span class="text">${charger.chargerId} </span>  <div class="circle red"></div></div>`;
                  }  
                  else if(chargePointStatus.startsWith("A")){
                    chargerInfoList += `<div class="chargerBlock"><span class="text">${charger.chargerId} </span>  <div class="circle green"></div></div>`;
                  }else{
                    chargerInfoList += `<div class="chargerBlock"><span class="text">${charger.chargerId} </span>  <div class="circle blue"></div></div>`;
                  }
                });
              }
              if(!isGreen){ marker.setImage(markerImageRed); }
            }
            
            tempHtml = tempHtml.replace('{chargerData}', chargerInfoList);
            iwContent = tempHtml;
          });
        });
          
        // let infowindow = new kakao.maps.InfoWindow({ content : iwContent });
        let infowindow = new kakao.maps.CustomOverlay({
          position,
          content : iwContent,
          yAnchor: 1.15
        });
        kakao.maps.event.addListener(marker, 'mouseover', async function() { 
          // infowindow.open(map, marker);
          infowindow.setMap(map); 
        });

        kakao.maps.event.addListener(marker, 'mouseout', function() { 
          // infowindow.close(); 
          infowindow.setMap(null);
        });

        kakao.maps.event.addListener(marker, 'click', function() {
          history.push('/chargers/' + element.statId);
        });
      });
    }).catch(error => {
      console.error('there was a problem with the fetch operation: ', error)
    })
  }, []);

  return <div id='map' style={{
    width: '100%',
    height: '500px'
  }}></div>;
};

export default Map;
