import React, {useState} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Alert from '@mui/material/Alert';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { NEONPV_CONFIG } from "config.js";
import { connect } from "react-redux";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
}));


/**
 * 일반 회원 수정 화면
 * @param {*} props 
 * @returns 
 */
const UserUpdateForm = (props) => {
  const { className, history, userInfo, user,  ...rest } = props;
  const classes = useStyles();
  const [alertOpen, setAlertOpen] = useState();
  const [alertText, setAlertText] = useState();
  const [userKind, setUserKind] = useState(userInfo.user_kind);

  const handleUserKindChange = (event) => {
    setUserKind(event.target.value);
  }


  const [userCardStop, setUserCardStop] = useState(userInfo.user_card_stop);

  const handleUserCardStop = (event) => {
    setUserCardStop(event.target.value);
  }


  const handleSubmit = (event, values) => {
    event.preventDefault();


    fetch(`${NEONPV_CONFIG.HOSTNAME}/user/updateUserInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: values.user_id,
        user_nickname: values.user_nickname,
        user_card_number: values.user_card_number,

        user_card_stop : userCardStop,
        user_kind: userKind,
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("업데이트에 성공했습니다.");

        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`업데이트에 실패했습니다.\n(${responseData.resultCode}):${responseData.resultMessage}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("업데이트에 실패했습니다.");
    });
  };


  /** 회원정보 삭제하기 */
  const handleRemoveUserInfo = (event) => {
    event.preventDefault();

    fetch(`${NEONPV_CONFIG.HOSTNAME}/user/removeUserInfo`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        user_id: userInfo.user_id,
        cardNo: userInfo.user_card_number
      }),
    })
    .then((response) => {
      return response.json();
    })
    .then((responseData) => {
      if (responseData.resultCode === "000") {
        alert("회원정보 삭제에 성공했습니다.");
        setTimeout(() => {
          history.goBack();
        }, 500);
      } else {
        //에러
        alert(`회원정보 삭제에 실패했습니다.\n${responseData.resultCode}`);
      }
    })
    .catch((error) => {
      console.error(error);
      alert("회원정보 삭제에 실패했습니다.");
    });
  }

  // URL 복사
  const copyURL = () => {
    if(!userInfo.user_card_number){
      alert("회원카드번호가 없습니다.\n등록 후 이용해 주십시오.")
      return;
    }

    const URL = `${window.location.origin}/CreditCard/${user.admin_id}/${userInfo.user_email}/${userInfo.user_card_number}`;

    if (navigator.clipboard) {
      navigator.clipboard.writeText(URL)
          .then(() => {
              setAlertOpen('success');
          })
          .catch(err => {
              console.error('클립보드에 텍스트를 복사하는 중 오류가 발생했습니다.', err);
              setAlertOpen('warning');
          });
    } else {
      try{
        const textArea = document.createElement('textarea')
        textArea.value = URL;
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)

        setAlertOpen('success');
      }catch(err){
        setAlertOpen('info');
        setAlertText(URL);
        alert("클립보드가 정상동작 하지 않았습니다.\n카드등록 버튼 우측의 URL을 복사 해주세요.");
      }
    }
  }

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Formik
      initialValues={{
        user_id: userInfo.user_id || "",
        user_login_type: userInfo.user_login_type || "",
        user_email: userInfo.user_email || "",
        user_password: userInfo.user_password || "",
        user_nickname: userInfo.user_nickname || "",
        user_card_number : userInfo.user_card_number || "",
        user_card_stop : userInfo.user_card_stop || "N",
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          className={clsx(classes.root, className)}
          onSubmit={(e) => handleSubmit(e, values)}
        >
          <Card>
            <CardContent>
              <Grid container spacing={2}>

                <Grid item md={2} xs={4}>
                  <TextField
                    fullWidth
                    label="운영회원사"
                    name="businessName"
                    value={user.admin_name}
                    variant="outlined"
                    inputProps={{ maxLength: 12 }}
                    // disabled={userAuth === 2} // 운영사 로그인 시 입력 불가
                    disabled
                  />
                </Grid>


                <Grid item md={2} xs={12}>
                  <TextField
                    error={Boolean(touched.user_id && errors.user_id)}
                    fullWidth
                    helperText={touched.user_id && errors.user_id}
                    label="ID"
                    disabled
                    name="user_id"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.user_id}
                    variant="outlined"
                    // inputProps={{ maxLength: 8 }}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(touched.user_login_type && errors.user_login_type)}
                    fullWidth
                    helperText={touched.user_login_type && errors.user_login_type}
                    label="로그인유형"
                    disabled
                    name="user_login_type"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.user_login_type}
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>

                <Grid item md={4}></Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_email && errors.user_email)}
                    fullWidth
                    helperText={touched.user_email && errors.user_email}
                    label="이메일"
                    disabled
                    name="user_email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.user_email}
                    variant="outlined"
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_password && errors.user_password)}
                    fullWidth
                    helperText={touched.user_password && errors.user_password}
                    label="패스워드"
                    disabled
                    name="user_password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    required
                    value={values.user_password}
                    variant="outlined"
                    inputProps={{ maxLength: 32 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_card_number && errors.user_card_number)}
                    fullWidth
                    helperText={touched.user_card_number && errors.user_card_number}
                    label="회원카드번호(16자리)"
                    name="user_card_number"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.user_card_number}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                    disabled
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                    <InputLabel variant="outlined" id="demo-simple-select-label-2">카드정지여부</InputLabel>

                    <Select
                      labelId="demo-simple-select-label2"
                      id="demo-simple-select-2"
                      value={userCardStop}
                      label="userCardStop"
                      onChange={handleUserCardStop}
                      variant="outlined"
                    >
                      <MenuItem value={'N'}>사용</MenuItem>
                      <MenuItem value={'Y'}>정지</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={3} xs={6}>
                  <TextField
                    error={Boolean(touched.user_nickname && errors.user_nickname)}
                    fullWidth
                    helperText={touched.user_nickname && errors.user_nickname}
                    label="닉네임"
                    name="user_nickname"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.user_nickname}
                    variant="outlined"
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>

                <Grid item md={3} xs={6}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                    <InputLabel variant="outlined" id="demo-simple-select-label">회원구분</InputLabel>

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userKind}
                      label="Kind"
                      onChange={handleUserKindChange}
                      variant="outlined"
                    >
                      <MenuItem value={'과금'}>과금</MenuItem>
                      <MenuItem value={'무과금'}>무과금</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid container item
              direction="row" alignItems="center" justify="center"
              className={classes.action}>
                  <Grid container direction="row" alignItems="center" justify="center" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={copyURL}
                  className={classes.action} // classes.action 클래스는 버튼에 중앙 정렬 스타일을 포함해야 합니다.
                  disabled
                >
                  카드등록
                </Button>
              </Grid>

              <Grid item>
                {alertOpen === 'success' && (
                  <Alert severity="success">URL 복사 완료.</Alert>
                )}

                {alertOpen === 'warning' && (
                  <Alert severity="warning">URL 복사 실패.</Alert>
                )}

                {alertOpen === 'info' && (
                  <Alert severity="info">{alertText}</Alert>
                )}
              </Grid>
            </Grid>

                </Grid>

              </Grid>


              <Grid container item className={classes.action}>
                <Grid container item md={6} xs={6}
                  className={classes.action}
                  direction="row" alignItems="center" justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    수정
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>

                </Grid>

                <Grid
                    container
                    direction="row" alignItems="center" justifyContent="flex-end"
                    item md={6} xs={6}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleRemoveUserInfo}
                      className={classes.action}
                    >
                      회원 삭제
                    </Button>
                  </Grid>
              </Grid>

             

            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

UserUpdateForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(UserUpdateForm);
