import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link as RouterLink, withRouter } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TablePagination,
  TableSortLabel,
  Grid,
  Checkbox
} from '@material-ui/core';

import {
  Search as SearchIcon,
} from 'react-feather';

import { NEONPV_CONFIG } from 'config.js';
import moment from 'moment';
import { USER_AUTH } from 'common/constants';
import { connect, useSelector} from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateQueryLoginType, updateQueryUserCardNumber, updateQueryUserEmail, updateQueryUserNickname } from 'actions/query';
import {CSVLink} from "react-csv";
import { string } from 'prop-types';
import { forEach } from 'underscore';


const COLUMN_WIDTH = {
  // USER_CARD_NUMBER: '14%',
  // USER_CARD_STOP: '6%', //24
  // LOGIN_TYPE: '10%', //34
  // EMAIL: '14%', //50
  // PASSWORD: '7%', //29
  // NICKNAME: '10%', //60
  // USER_KIND: '9%', //70
  // REGISTER_DATE: '12%', //84
  // DELETE_STATUS: '10%', //94
  // DELETE_TIME: '12%', //108
}



function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



const headCells = [
  {
    id: 'statId',
    numeric: false,
    disablePadding: true,
    label: '충전소',
  },
  {
    id: 'statName',
    numeric: false,
    disablePadding: true,
    label: '충전소 명',
  },
  {
    id: 'cnt',
    numeric: false,
    disablePadding: false,
    label: '충전기 대수',
  },
  {
    id: 'telno',
    numeric: false,
    disablePadding: false,
    label: '연락처',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'center'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {/* {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



/**
 * 회원 조회
 * @param {*} props 
 * @returns 
 */
const RoamingTable = props => {
  const { className, user, queryParams, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('statId');

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [memberName, setMemberName] = useState(''); //회원사라면 로그인한 이름, 관리자면 null
  const [loginType, setLoginType] = useState('');
  const [userCardNumber, setUserCardNumber] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userNickname, setUserNickname] = useState('');


  const searchData = () => {
    var url = `${NEONPV_CONFIG.HOSTNAME}/emergency/contacts/stat?`;

    var params = `auth=${userAuth}&`;
    if(user.admin_id !== "admin"){
      params += "memberId=" + user.admin_id + "&"; 
    }
    if(memberName != ""){
      params += "statId=" + memberName + "&";
    }

    url += params;
    
    fetch(url)
    .then(res => res.json())
    .then(snapshot => {
      const list  = snapshot;
      const csvlist = [];

      list.forEach(doc => {
        csvlist.push({
          '충전소': '=""' + doc.statId + '""',
          '충전기': doc.chargerId,
          '연락처': doc.telno,
          '마지막 수정시간': doc.updatetime ? moment(doc.updatetime).format('YYYY-MM-DD HH:mm') : ' ',
        });
      })

      setListData( list );
      setCsvData(csvlist);

    }
    );
  }

  const getEvents = () => {
    var url = `${NEONPV_CONFIG.HOSTNAME}/emergency/events/fire?`;

    var params = `invalid=0&`;
    if(user.admin_id !== "admin"){
      params += "memberId=" + user.admin_id + "&"; 
    }
    if(memberName != ""){
      params += "statId=" + memberName + "&";
    }

    url += params;
    
    fetch(url)
    .then(res => res.json())
    .then(snapshot => {
      if(snapshot.status !== 200 && snapshot.length > 0){
        let message = "";
        snapshot.forEach(data => {
          message += `${data.Message} : ${data.statId}-${data.chargerId}\n`;
        })
        // alert(message);
      }
    }
    );
  }


  useEffect( () => {
    getEvents();
    searchData(memberName, queryParams.queryLoginType, queryParams.queryUserCardNumber, queryParams.queryUserEmail, queryParams.queryUserNickname);

    setLoginType(queryParams.queryLoginType);
    setUserCardNumber(queryParams.queryUserCardNumber);
    setUserEmail(queryParams.queryUserEmail);
    setUserNickname(queryParams.queryUserNickname)

    return () => {};
  }, []);

  const handleSearch = event => {
    searchData(memberName, loginType, userCardNumber, userEmail, userNickname);
  }

  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }

  /**
   * 검색 정보 지우기
   * @param {*} event 
   */
  const handleClear = event => {
    props.updateQueryLoginType('ALL');
    props.updateQueryUserCardNumber('');
    props.updateQueryUserEmail('');
    props.updateQueryUserNickname('');

    setMemberName(userAuth === USER_AUTH.MEMBER ? user.admin_name : '');
    setLoginType('ALL');
    setUserCardNumber('');
    setUserEmail('');
    setUserNickname('');
  }

  const handleQueryMemberNameChange = (event) => {
    event.persist();
    setMemberName(event.target.value);
  }

  const handleLoginTypeChange = (event) => {
    event.persist();
    props.updateQueryLoginType(event.target.value);
    setLoginType(event.target.value);
  }

  const handleUserCardNumber = (event) => {
    event.persist();
    props.updateQueryUserCardNumber(event.target.value);
    setUserCardNumber(event.target.value);
  }

  const handleUserEmailChange = (event) => {
    event.persist();
    props.updateQueryUserEmail(event.target.value);
    setUserEmail(event.target.value);
  }

  const handleUserNicknameChange = (event) => {
    event.persist();
    props.updateQueryUserNickname(event.target.value);
    setUserNickname(event.target.value);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page*rowsPerPage);


  const userClassifies = [
    {
      value: 'ALL',
      label: 'ALL'
    },
    {
      value: 'user_email',
      label: 'EMAIL'
    },
    {
      value: 'NAVER_ID',
      label: 'NAVER'
    },
  ];


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  return (

    <Card className={clsx(classes.root, className)} {...rest} >

      <Box m={1} p={2}>

        <Box mt={2}
          display="flex"
          alignItems="center"
          justifyContent="start"
          flexGrow={1}>

          <TextField className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                </InputAdornment>
              )
            }}

            disabled={userAuth !== USER_AUTH.ADMIN}
            onChange={handleQueryMemberNameChange}
            label="충전소"
            placeholder="충전소"
            value={memberName}
            variant="outlined"
            onKeyUp={(ev) => doKeyPress(ev)}
          />

          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.bulkAction}
            onClick={handleSearch}
          >
            검색
          </Button>

        </Box>
      </Box>


      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={listData.length} />

              <TableBody>
                { stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (

                  <TableRow
                    className={classes.tableRow}
                    key={module.id}
                    component={RouterLink} to={`/emergency/detail/${module.statId}`}
                    >

                    <TableCell align="center">{module.statId}</TableCell>
                    <TableCell align="center">{module.statName}</TableCell>
                    <TableCell align="center">{module.cnt}</TableCell>
                    <TableCell align="center">{module.businessTel}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>

      <CardActions className={classes.actions}>

        <Grid item md={6} justify="flex-start">

          <CSVLink
            variant="contained"
            size="large"
            data={csvData}
            filename={`${new Date().toLocaleDateString()}_비상연락망.csv`}
            className="btn btn-primary"
            target="_blank"
            disabled={csvData.length === 0}
            data-interception='off'
          >

            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.bulkAction}
              disabled={csvData.length === 0}
              style={{ marginRight: 30 }}
            >
              CSV 다운로드
            </Button>
          </CSVLink>
        </Grid>

        <Grid item md={6} justify="flex-end">

          <TablePagination
            component="div"
            count={listData.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Grid>
      </CardActions>

    </Card>
  );
};

RoamingTable.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateQueryLoginType,
    updateQueryUserCardNumber,
    updateQueryUserEmail,
    updateQueryUserNickname}, dispatch);
};

let mapStateToProps = (state) => {
  return {
    queryParams: state.queryParams,
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RoamingTable));




const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 260,
    marginRight:20,
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
}));
