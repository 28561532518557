import React, { useState, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link as RouterLink, withRouter, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  InputAdornment,
  SvgIcon,

  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TablePagination,
  TableSortLabel,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,

  Grid,

  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";

import {
  Search as SearchIcon,
} from "react-feather";

import { NEONPV_CONFIG } from "config.js";

import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import {
  updateQueryChargerMemberName,
  updateQueryChargerStatId,
  updateQueryStationName2,
  updateQueryStationAddr2,
  updateQueryChargerNo,
  updateQueryChargerStatus
} from "actions/query";
import { getChargerTypeLabel, USER_AUTH } from 'common/constants';

import { CSVLink } from "react-csv";
import moment from 'moment';

export const charger_status_list = ['-', 'Available', 'Preparing', 'Charging',
'SuspendedEV','SuspendedEVSE','Finishing','Reserved','Unavailable','Faulted'];

const COLUMN_WIDTH = {
  NO: '4%',
  STATION_ID: '4%',
  STATION_NAME: '14%', //20
  STATION_ADDR: '10%', //35
  CHARGER_NO: '7%', //52
  SERIAL_NUMBER: '7%', //45
  POWER_TYPE: '7%', //60
  CHARGER_TYPE: '6%', //67
  CHARGER_STATUS: '7%', //75
  CHARGER_HEARTBEAT : '8%',
  CHARGER_MODEL: '10%', //82
  CHARGER_FIRMWARE_VERSION: '8%', //92
  MEMBER_NAME: '7%', //100
}


function descendingComparator(a, b, orderBy) {

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}


function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // {
  //   id: 'no',
  //   numeric: false,
  //   disablePadding: true,
  //   width: COLUMN_WIDTH.NO,
  //   label: 'No',
  // },
  {
    id: 'statId',
    numeric: false,
    disablePadding: false,
    labelsmall : true,
    width: COLUMN_WIDTH.STATION_ID,
    label: 'ID',
  },
  {
    id: 'statName',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.STATION_NAME,
    label: '충전소',
  },
  {
    id: 'addr',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.STATION_ADDR,
    label: '주소',
  },

  {
    id: 'chargerId',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.CHARGER_NO,
    label: '충전기ID',
  },
  {
    id: 'serialNumber',
    numeric: false,
    disablePadding: true,
    labelsmall : true,
    width: COLUMN_WIDTH.SERIAL_NUMBER,
    label: 'Mac',
  },
  {
    id: 'powerType',
    numeric: false,
    disablePadding: false,
    labelsmall : true,
    width: COLUMN_WIDTH.POWER_TYPE,
    label: '충전속도',
  },
  {
    id: 'chargerType',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.CHARGER_TYPE,
    label: '타입',
  },
  {
    id: 'chargePointStatus',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.CHARGER_STATUS,
    label: '상태',
  },
  {
    id: 'lastHeartbeatTime',
    numeric: false,
    disablePadding: false,
    labelsmall : true,
    width: COLUMN_WIDTH.CHARGER_HEARTBEAT,
    label: '최근수신시각',
  },
  {
    id: 'chargerModel',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.CHARGER_MODEL,
    label: '모델',
  },
  {
    id: 'chargerFirmwareVersion',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.CHARGER_FIRMWARE_VERSION,
    label: '펌웨어버전',
  },
  {
    id: 'member_name',
    numeric: false,
    disablePadding: false,
    width: COLUMN_WIDTH.MEMBER_NAME,
    label: '회원사',
  },
];


function EnhancedTableHead(props) {
  const { order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? 'right' : 'center'}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            width={headCell.width}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.labelsmall ? <Typography variant="body2"> {headCell.label} </Typography> : headCell.label }

              {/*
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null} */}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};



const ChargerTable = (props) => {
  const history = useHistory();
  const { className, user, match, queryParams, ...rest } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('no');

  const userAuth = useSelector((state) => state.user.user_auth);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [listData, setListData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [memberName, setMemberName] = useState(userAuth === USER_AUTH.ADMIN ? '' : user.admin_name);
  const [queryStatId, setQueryStatId] = useState(match.params.statId ? match.params.statId : "");
  const [queryName, setQueryName] = useState("");
  const [queryAddr, setQueryAddr] = useState("");
  const [queryChargerNo, setQueryChargerNo] = useState("");
  const [queryChargerStatus, setQueryChargerStatus] = useState("-");

  const searchData = (autoload = false, memberName, statId, statName, addr, chargerNo, chargerStatus) => {
    
    var url = `${NEONPV_CONFIG.HOSTNAME}/station/queryChargerList?`;

    var params = "";

    // 콜센터 계정이라면
    if(user.admin_id == 'callcenter'){
      params += "memberId=castpro&";
    }
    //회원사 계정이라면
    else if (userAuth === USER_AUTH.MEMBER) {
      params += "memberId=" + user.admin_id + "&";
    } 
    // 서브계정
    else if (userAuth === USER_AUTH.SUB_MEMBER){
      params += "memberId=" + user.admin_id + "&";
    }
    else if (memberName) {
      //관리자 계정이라면
      params += "memberName=" + memberName + "&";
    }

    if (statId) {
      params += "statId=" + statId + "&";
    }

    if (statName) {
      params += "statName=" + statName + '&';
    }

    if (addr) {
      params += "addr=" + addr + '&';
    }

    if (chargerNo) {
      params += "chargerNo=" + chargerNo + '&';
    }

    if (chargerStatus && chargerStatus != '-') {
      params += "chargerStatus=" + chargerStatus + '&';
    }


    url += params;

    fetch(url)
      .then((res) => res.json())
      .then((snapshot) => {
        var i = 1;
        var list = [];
        var csvlist = [];
        if (snapshot.resultData != null) {
          snapshot.resultData.forEach((doc) => {
            doc.no = i++;
            list.push(doc);

            csvlist.push({
              '충전소ID': '=""' + doc.statId + '""',
              '충전소명': doc.statName,
              '주소': "" + doc.addr,
              // '충전기번호': ""+doc.chargerId,
              '충전기번호': '=""' + doc.chargerId + '""',
              '시리얼번호': "" + doc.serialNumber,
              "맥주소": doc.chargerMac,
              '충전속도': doc.powerType,
              '타입': getChargerTypeLabel(doc.chargerType),
              '상태': doc.chargePointStatus || "",
              '최신수신시각': moment(doc.lastHeartbeatTime).format('YYYY-MM-DD HH:mm:hh') || "",
              '모델': doc.chargerModel || "",
              '펌웨어버전': doc.chargerFirmwareVersion || "",
              '운영회원사': doc.member_name || "",
            })
          });
        }
        setListData(list);
        setCsvData(csvlist);


        if (autoload === false && list.length === 0) {
          setDialogMessage('검색 결과가 없습니다.');
          setOpenDialog(true);
        }

        setPage(0);
      });
  };



  const handleSearch = (event) => {
    if (userAuth === USER_AUTH.ADMIN || memberName !== '' || queryStatId != '' || queryName != '' || queryAddr != '') {
      searchData(false, memberName, queryStatId, queryName, queryAddr, queryChargerNo, queryChargerStatus);
    } else {
      setDialogMessage('검색어를 입력해 주세요. ');
      setOpenDialog(true);
    }
  };


  const doKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      handleSearch();
    }
  }


  /**
   * 검색 정보 지우기
   * @param {*} event
   */
  const handleClear = (event) => {
    history.push('/chargers/');

    props.updateQueryChargerStatId("");
    props.updateQueryStationName2("");
    props.updateQueryStationAddr2("");
    props.updateQueryChargerNo("");
    props.updateQueryChargerStatus("-");

    setMemberName(userAuth === USER_AUTH.ADMIN ? '' : user.admin_name);
    setQueryStatId("");
    setQueryName("");
    setQueryAddr("");
    setQueryChargerNo("");
    setQueryChargerStatus("-");
    setListData([]);
  };

  const handleQueryStatIdChange = (event) => {
    event.persist();
    props.updateQueryChargerStatId(event.target.value);
    setQueryStatId(event.target.value);
  }

  const handleQueryMemberNameChange = (event) => {
    event.persist();
    props.updateQueryChargerMemberName(event.target.value);
    setMemberName(event.target.value);
  }

  const handleQueryNameChange = (event) => {
    event.persist();
    props.updateQueryStationName2(event.target.value);
    setQueryName(event.target.value);
  };

  const handleQueryAddrChange = (event) => {
    event.persist();
    props.updateQueryStationAddr2(event.target.value);
    setQueryAddr(event.target.value);
  };

  const handleQueryChargerNo = (event) => {
    event.persist();
    props.updateQueryChargerNo(event.target.value);
    setQueryChargerNo(event.target.value);
  };

  const handleQueryChargerStatus = (event) => {
    event.persist();
    props.updateQueryChargerStatus(event.target.value);
    setQueryChargerStatus(event.target.value);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };


  const startRow = (page * rowsPerPage);


  useEffect(() => {
    //TODO:....
    //충전기번호는 각 충전소 충전기 번호와 중복되므로... 디폴트 검색에서 제외함.
    if (queryParams.queryChargerMemberName ||
      queryParams.queryChargerStatId ||
      queryParams.queryStatName2 || queryParams.queryStatAddr2) {
      searchData(true, queryParams.queryChargerMemberName,
        queryParams.queryChargerStatId,
        queryParams.queryStatName2, queryParams.queryStatAddr2, queryParams.queryChargerNo,
        queryParams.queryChargerStatus);
    }else{
      handleSearch();
    }


    setQueryStatId(queryParams.queryChargerStatId ? queryParams.queryChargerStatId : match.params.statId);
    setQueryName(queryParams.queryStatName2);
    setQueryAddr(queryParams.queryStatAddr2);
    setQueryChargerNo(queryParams.queryChargerNo);
    setQueryChargerStatus(queryParams.queryChargerStatus);


    if (userAuth === USER_AUTH.MEMBER) {
      setMemberName(user.admin_name);
    } 
    else if(userAuth === USER_AUTH.SUB_MEMBER){
      setMemberName(user.admin_name);
    }
    else {
      setMemberName(queryParams.queryChargerMemberName);
    }

    return () => { };
  }, []);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Mac주소 복사
  const copyMac = (mac) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(mac)
          .then(() => {
              alert("Mac주소 복사 완료");
          })
          .catch(err => {
              console.error('클립보드에 텍스트를 복사하는 중 오류가 발생했습니다.', err);
              alert('클립보드에 텍스트를 복사하는 중 오류가 발생했습니다.');
          });
    } else {
      try{
        const textArea = document.createElement('textarea')
        textArea.value = mac;
        document.body.appendChild(textArea)
        textArea.select()
        document.execCommand('copy')
        document.body.removeChild(textArea)

        alert("Mac주소 복사 완료");
      }catch(err){
        console.log(err);
        alert("클립보드가 정상동작 하지 않았습니다.\n카드등록 버튼 우측의 URL을 복사 해주세요.");
      }
    }
  }


  return (

    <div>
      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>주의</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>

      <Card className={clsx(classes.root, className)} {...rest}>
        <Box m={1} p={2}>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="start"
            flexGrow={1}
          >

            <TextField className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              disabled={userAuth !== USER_AUTH.ADMIN}
              onChange={handleQueryMemberNameChange}
              label="운영회원사"
              placeholder="운영회원사"
              value={memberName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action"><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}

              onChange={handleQueryStatIdChange}
              label="충전소ID"
              placeholder="충전소ID"
              value={queryStatId}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryNameChange}
              label="충전소"
              placeholder="충전소"
              value={queryName}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryAddrChange}
              label="충전소 주소"
              placeholder="충전소 주소"
              value={queryAddr}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={handleQueryChargerNo}
              label="충전기 번호"
              placeholder="충전기 번호"
              value={queryChargerNo}
              variant="outlined"
              onKeyUp={(ev) => doKeyPress(ev)}
            />


            <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.queryField}>
              <InputLabel variant="outlined" id="demo-simple-select-label-2">충전기 상태</InputLabel>

              <Select
                labelId="demo-simple-select-label2"
                id="demo-simple-select-2"
                value={queryChargerStatus}
                label="충전기상태"
                onChange={handleQueryChargerStatus}
                variant="outlined"
              >

                {charger_status_list.map(item => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}

              </Select>
            </FormControl>


            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.bulkAction}
              onClick={handleSearch}
            >
              검색
            </Button>

            <Button
              variant="outlined"
              size="large"
              className={classes.bulkAction}
              onClick={handleClear}
            >
              초기화
            </Button>
          </Box>
        </Box>

        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <TableContainer style={{ width: '100%' }}>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={listData.length}
                />

                <TableBody>
                  {stableSort(listData, getComparator(order, orderBy)).slice(0 + startRow, rowsPerPage + (page * rowsPerPage)).map(module => (
                    <TableRow
                      className={classes.tableRow}
                      key={module.SeqNo}
                      component={RouterLink}
                      to={`/chargers/update/${module.SeqNo}`}
                    >
                      
                      <TableCell width={COLUMN_WIDTH.STATION_ID}><Typography variant="body2">{module.statId}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STATION_NAME}><Typography variant="body1">{module.statName}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.STATION_ADDR}><Typography variant="body2">{module.addr}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGER_NO}><Typography variant="body1">{module.chargerId}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.SERIAL_NUMBER} onClick={ event => {event.preventDefault(); copyMac(module.chargerMac) }}>{module.chargerMac}</TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.POWER_TYPE}><Typography variant="body1">{module.powerType}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGER_TYPE}><Typography variant="body1">{getChargerTypeLabel(module.chargerType)}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGER_STATUS}><Typography variant="body1">{module.chargePointStatus}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGER_HEARTBEAT}><Typography variant="body2">{moment(module.lastHeartbeatTime).format('YYYY-MM-DD HH:mm:hh')}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.CHARGER_MODEL}><Typography variant="body2">{module.chargerModel}</Typography></TableCell>
                      <TableCell width={COLUMN_WIDTH.CHARGER_FIRMWARE_VERSION}><Typography variant="body2">{module.chargerFirmwareVersion}</Typography></TableCell>
                      <TableCell align="center" width={COLUMN_WIDTH.MEMBER_NAME}><Typography variant="body2">{module.member_name}</Typography></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              </TableContainer>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>

          <Grid item md={6} justify="flex-start">

            <CSVLink
              variant="contained"
              size="large"
              data={csvData}
              filename={`${new Date().toLocaleDateString()}_충전기목록.csv`}
              className="btn btn-primary"
              target="_blank"
              disabled={csvData.length === 0}
              data-interception='off'
            >

              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classes.bulkAction}
                disabled={csvData.length === 0}
              >
                CSV 다운로드
              </Button>
            </CSVLink>
          </Grid>

          <Grid item md={6} justify="flex-end">

            <TablePagination
              component="div"
              count={listData.length}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Grid>
        </CardActions>
      </Card>

    </div>
  );
};

ChargerTable.propTypes = {
  className: PropTypes.string,
};


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateQueryChargerMemberName,
      updateQueryChargerStatId,
      updateQueryStationName2,
      updateQueryStationAddr2,
      updateQueryChargerNo,
      updateQueryChargerStatus,
    },
    dispatch
  );
};

let mapStateToProps = (state) => {
  return {
    queryParams: state.queryParams,
    user: state.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ChargerTable));



const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050
  },
  nameContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  queryField: {
    // width: 300
    flexBasis: 180,
    marginRight: 20,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  bulkAction: {
    marginLeft: theme.spacing(2),
  },
}));
