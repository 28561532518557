import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { SubmemberList } from "./components";
import { Button, SvgIcon } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {PlusCircle as PlusCircleIcon } from "react-feather";

const page = (props) => {
  const classes = useStyles();
  const [statId, setStateId] = useState(props.match.params.statId);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Button
          color="primary"
          variant="contained"
          size="large"
          component={RouterLink} to={`/emergency/add`}
        >
          <SvgIcon fontSize="small" className={classes.actionIcon}>
            <PlusCircleIcon />
          </SvgIcon>
           비상연락망
        </Button>
      </div>

      <div className={classes.content}>
        <SubmemberList statId={statId} />
      </div>
    </div>
  );
};

export default page;



const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  actionIcon: {
    marginRight: theme.spacing(1)
  }  
}));