import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles,

  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,

  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

//const userAuth = useSelector((state) => state.user.user_auth);
import { NEONPV_CONFIG } from "config.js";
import DaumPostcode from 'react-daum-postcode';
import md5 from 'md5';
import evchargerApp from "firebaseHellocharger";

const SubmemberAddForm = (props) => {
  const { className, history, user, ...rest } = props;
  const classes = useStyles();

  const ref = useRef(null);
  const [isOpenPost, setIsOpenPost] = useState(false);

  const onChangeOpenPost = () => {
    setIsOpenPost(!isOpenPost);
  };


  const onCompletePost = (data) => {
    let fullAddr = data.address; //도로명 주소
    let extraAddr = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddr += data.bname; //(구) 동 이름
      }
      if (data.buildingName !== '') {
        extraAddr += extraAddr !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      // fullAddr += extraAddr !== '' ? ` (${extraAddr})` : '';
      extraAddr = extraAddr !== '' ? ` (${extraAddr})` : '';
    }

    ref.current.values.memberPostCode = data.zonecode;
    ref.current.values.memberAddr = data.address;
    ref.current.values.memberAddrDetail = extraAddr;

    setIsOpenPost(false);
  };


  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  const validate = (values) => {
    let errors = {};

    //비밀번호 일치 여부 check
    if (values.memberPassword !== values.memberPasswordConfirm) {
      errors.memberPasswordConfirm = "비밀번호가 일치하지 않습니다.";
    }

    return errors;
  }


  const handleSubmit = async (event, values) => {
    event.preventDefault();
    
    if (String(values.statId).length > 8) { alert("충전소 길이 제한은 최대 8자리 입니다."); }
    else if (values.chargerId && String(values.chargerId).length > 2) { alert("충전기 길이 제한은 최대 2자리 입니다."); }
    else if (String(values.telno).length > 20){ alert("연락처 길이 제한은 최대 20자리 입니다."); }
    else if (!/^\d+$/.test(String(values.telno))) { alert("연락처는 숫자만 가능합니다."); }
    else{
      var url = `${NEONPV_CONFIG.HOSTNAME}/emergency/contacts`;
      fetch(url, {
        method: "POST",
        headers:{
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          statId: values.statId,
          chargerId: values.chargerId,
          telno: values.telno,
        })
      })
      .then(res => {
        if(res.status === 200){
          pushContactsApp(values.statId, values.chargerId)
          alert("등록 하였습니다.");
          history.goBack();
        }else{
          alert("비상연락망 등록에 실패 하였습니다.");
        }
      });
    }
  };

  // 맥주소 검색해서 앱에 동기화
  const pushContactsApp = ((statId, chargerId) => {
    var url = `${NEONPV_CONFIG.HOSTNAME}/emergency/getMacAddress?`;
    if(statId){url += `statId=${statId}&`}
    if(chargerId){url += `chargerId=${chargerId}`}

    fetch(url)
    .then(res => { return res.json() })
    .then(res => {
      res.forEach(chargerMac => {
        asyncContacts(chargerMac)
      })
    });
  })

  // 서버에 있는 연락처 동기화
  const asyncContacts = ((chargerMac) => {
    const database = evchargerApp.database();
    const dataRef = database.ref(`auth/${chargerMac}/command`);

    dataRef.set("update_emergency_contacts")
      .then(() => {
        console.log("Contacts push app success")
      }).catch((error) => {
        console.error('firebase set data fail - ', error);
      })
  });


  const handleBack = () => {
    history.goBack();
  };


  return (
    <div>
      <Dialog open={openDialog} onClose={() => { setOpenDialog(false) }}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={() => { setOpenDialog(false) }}>닫기</Button>
        </DialogActions>
      </Dialog>


      <Formik
        innerRef={ref}
        enableReinitialize={false}
        initialValues={{
        }}
        validate={validate}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            className={clsx(classes.root, className)}
            onSubmit={(e) => handleSubmit(e, values)}
          >
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={2} xs={4}>
                    <TextField
                      error={Boolean(touched.statId && errors.statId)}
                      fullWidth
                      helperText={touched.statId && errors.statId}
                      label="충전소"
                      name="statId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.statId}
                      variant="outlined"
                      inputProps={{ maxLength: 12 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.chargerId && errors.chargerId)}
                      fullWidth
                      helperText={touched.chargerId && errors.chargerId}
                      label="충전기"
                      name="chargerId"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.chargerId}
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>

                  <Grid item md={3} xs={6}>
                    <TextField
                      error={Boolean(touched.telno && errors.telno)}
                      fullWidth
                      helperText={touched.telno && errors.telno}
                      label="연락처"
                      name="telno"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                      value={values.telno}
                      variant="outlined"
                      inputProps={{ maxLength: 20 }}
                    />
                  </Grid>
                </Grid>

                <Grid container item className={classes.action}
                  direction="row" alignItems="center" justify="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    disabled={isSubmitting}
                    className={classes.action}
                  >
                    비상연락망 추가
                  </Button>

                  <Button
                    variant="outlined"
                    size="large"
                    onClick={handleBack}
                    className={classes.action}
                  >
                    취소
                  </Button>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </div>
  );
};

SubmemberAddForm.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

let mapStateToProps = (state) => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(SubmemberAddForm);


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  action: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "& + &": {
      marginLeft: theme.spacing(1),
    },
  },
  dividerInset: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: '#616161'
  },
  postCodeStyle: {
    display: 'block',
    position: 'relative',
    top: '0%',
    width: '400px',
    // height: '400px',
    // padding: '7px',
  },
}));
